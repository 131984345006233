import { Injectable } from '@angular/core';
import { Token, User } from '../models/user.model';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class JwtService
{
    public get isTokenExpired()
    {
        return this.jwtHelper.isTokenExpired(this.getJwt())
    }

    public get groups(): string[]
    {
        const token = this.getJwt();
        if (!token)
            return [];
        const tokenDecoded = this.decodeToken(token);
        return tokenDecoded.Grupos.split(",");
    }

    public get name(): string | ""
    {
        const token = this.getJwt();

        if (!token)
            return "";

        const tokenDecoded = this.decodeToken(token);
        return tokenDecoded.Nome;
    }

    public userIsInGroup(group: string)
    {
        const groups = this.groups;

        if (groups.length === 0)
            return false;

        return groups.includes(group.toUpperCase());
    }

    constructor(private jwtHelper: JwtHelperService) { }

    private decodeToken(token: string)
    {
        return this.jwtHelper.decodeToken(token) as Token;
    }

    private getJwt(): string | null
    {
        const userJson = localStorage.getItem("bfs-user");
        if (!userJson)
            return null;
        const user = JSON.parse(userJson) as User;
        return user.token;
    }
}
