import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './shared/guards/jwt.guard';

const routes: Routes = [
    {
        path: '', redirectTo: '/public', pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: async () => (await import('./auth/login/login.module')).LoginModule
    },
    {
        path: 'admin',
        loadChildren: async () => (await import('./admin/admin.module')).AdminModule,
        canActivate: [() => inject(AuthGuardService).canActivate()]
    },
    {
        path: 'public',
        loadChildren: async() => (await import('./public/public.module')).PublicModule,
    },
    {
        path: 'pesquisa',
        loadChildren: async() => (await import('./pesquisa/pesquisa.module')).PesquisaModule,
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
