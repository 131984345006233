import { Component, OnInit, inject } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit
{
    public title = "Dia do Algodão ABAPA 2024";
    public description = "A terceira edição do Dia do Algodão da Abapa, o maior encontro da cadeia produtiva do algodão no Nordeste e Matopiba, acontecerá no dia 20 de julho, na Fazenda Orquídeas, do Grupo Schmidt, em Barreiras. Com o tema “O Presente da Transformação”, o evento reunirá produtores e profissionais do setor para trocar experiências e conhecimentos que impulsionam a evolução da cotonicultura baiana."

    ngOnInit(): void
    {
        
    }
}