import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withJsonpSupport } from '@angular/common/http'
import { AuthService } from './shared/services/auth.service';
import { MessageService } from 'primeng/api';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { JwtService } from './shared/services/jwt.service';
import { AuthGuardService } from './shared/guards/jwt.guard';
import { ToastService } from './shared/services/toast.service';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { HttpsRequestInterceptor } from './shared/interceptors/token.interceptor';
import { UnauthorizedInterceptor } from './shared/interceptors/unautorized.interceptor';

registerLocaleData(localePt);

function getJwt() {
	return localStorage.getItem("bfs-user")
}
@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		CalendarModule,
		FormsModule,
		HttpClientModule,
		JwtModule.forRoot({
			config: {
				tokenGetter: getJwt
			}
		})
	],
	providers: [AuthService,
		MessageService,
		JwtHelperService,
		JwtService,
		AuthGuardService,
		provideHttpClient(withJsonpSupport()),
		{ provide: LOCALE_ID, useValue: 'pt' },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpsRequestInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UnauthorizedInterceptor,
			multi: true,
		},
		ToastService],
	bootstrap: [AppComponent]
})
export class AppModule { }
